<template>
  <div class="sm:py-4">
    <form
      class="p-4 sm:rounded sm:shadow sm:max-w-sm mx-auto bg-white"
      @submit.prevent="submitYo"
    >
      <h1 class="mb-8">Sign Up</h1>
      <a href="/social/login/google/login">
        <mdc-button
          :style="{ backgroundColor: '#4285F4' }"
          ripple
          raised
          class="w-full mb-4 relative"
        >
          <svg-google class="absolute bg-white top-0 left-0 w-9" />
          Sign up with Google
        </mdc-button>
      </a>
      <!-- <a href="/social/login/facebook/login">
        <mdc-button
          :style="{ backgroundColor: '#3b5998' }"
          ripple
          raised
          class="w-full mb-4 relative"
        >
          <svg-facebook
            :style="{ width: '28px', left: '5px', bottom: '-3px' }"
            class="absolute text-white"
            path="social/facebook.svg"
          />
          Sign up with Facebook
        </mdc-button>
      </a> -->
      <h2 class="my-4">Or sign up with email</h2>
      <mdc-textfield
        id="name"
        v-model="name"
        label="Name"
        type="text"
        class="w-full"
      />
      <mdc-helpertext
        id="name"
        text="Your name is used in emails you send. Ex: &#8220;William S. Preston, Esq.&#8221; &#60;bill@batea.com&#62;"
      />
      <mdc-textfield
        id="email"
        v-model="email"
        label="Email"
        type="email"
        class="w-full"
        required
        autocomplete="username email"
      />
      <mdc-helpertext
        id="email"
        text="You'll receive an email at this address to verify you are the owner."
      />
      <mdc-textfield
        id="password1"
        v-model="password"
        label="Password"
        type="password"
        class="w-full"
        required
        autocomplete="new-password"
      />
      <mdc-textfield
        id="password2"
        v-model="password_confirmation"
        label="Confirm Password"
        type="password"
        class="w-full"
        required
        autocomplete="new-password"
      />
      <mdc-checkbox v-model="terms" required class="mt-6">
        By creating an account, you agree to our
        <router-link target="_blank" to="/terms"> terms of service </router-link
        >, including never sending spam.
      </mdc-checkbox>
      <mdc-button
        :disabled="$store.state.loading"
        class="w-full my-6"
        type="submit"
        ripple
        raised
        secondary
      >
        Sign Up
      </mdc-button>
    </form>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import MdcButton from '@/components/mdc/button.vue';
import MdcCheckbox from '@/components/mdc/checkbox.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';
import MdcHelpertext from '@/components/mdc/helpertext.vue';
import SvgGoogle from '@/svg/social/google.svg';

export default {
  components: {
    MdcButton,
    MdcCheckbox,
    MdcHelpertext,
    MdcTextfield,
    SvgGoogle,
  },

  data: () => ({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
  }),

  mounted() {
    if (this.$store.state.isLoggedIn) this.$router.push('/account');
  },

  methods: {
    submitYo() {
      axios
        .post('/register', {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then((response) => {
          this.$store.commit('user', response.data);
          this.$router.push('/email');
        });
    },
  },
};
</script>
